@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@font-face {
    font-family: 'SF Pro Display';
    src:  url('../fonts/SF-Pro-Display-Black.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

.max-h-65{
    height: 65px;
}
.max-h-100{
    height: 100px;
}
.c-green{
    color: #b7b7b7;
}
.text-whatsapp-green{
    color:#25D366;
}
.text-20{
    font-size: 20rem;
}
.c-yellow{
    color: #f9ac25;
}
.h-50{
    height: 50px;
}
.h-65{
    height: 65px;
}
.m-left-10{
    margin-left: 10px;
}
.pb-30{
    padding-bottom: 6rem;
}
.opacity-0{
    opacity: 0;
}
.r-p10{
    margin-right: 10px;
}
.small-text {
    font-size: 13px!important;
    line-height: 20px!important;
    padding-top: 5px;
}
.text-greeniitblue{
    color: #25a5c4!important;
}
.text-greeniitblue:hover{
    color: #0a6076!important;
}
.title-holder {
    top: 50%;
    left: 40%;
    position: absolute;
  }
  .title {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    color: #f9d566;
    width: 0;
    overflow: hidden;
    white-space: no-wrap;
    display:block;
    border-right: 3px solid #f9d566;
    animation: typewriter 5s steps(7) infinite, blinking-cursor 0.8s infinite;
    animation-fill-mode: forwards
  }
  
  @keyframes typewriter {
    0% { width:0 }
    25% { width: 70% }
    48% { width: 100% }
    50% { width: 100% }
    55% { width: 100% }
    75% { width: 70%; }
    85% { width: 30%; }
    100% { width: 0; }
  }
  
  @keyframes blinking-cursor {
    from { border-color: transparent }
    to { border-color: #f9d566 }
  }
.h-300{
    height: 300px;
}
.m-auto{
    margin: 0 auto;
}

.page-title-area {
    position: relative;
    z-index: 1;
    background-image: url(../img/about-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
}
.page-title-area::before {
    background-image: linear-gradient(to right, #1ba5c4 , #b6d75e);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0.9;
    z-index: -1;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table {
    display: table!important;
}
.d-table-cell {
    vertical-align: middle;
}
.d-table-cell {
    display: table-cell!important;
}
.page-title-content {
    text-align: center;
    margin-top: 45px;
}
.page-title-content h2 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 42px;
    font-weight: 700;
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.section-title {
    text-align: center;
    margin-bottom: 50px;
    line-height: 1;
}
.section-title span {
    font-size: 16px;
    font-weight: 400;
    color: #00b0ee;
}
.section-title h2 {
    font-size: 40px;
    color: #393953;
    margin: 10px 0 0 0;
}
.process-item {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}
.process-item img {
    margin-bottom: 15px;
    margin: 0 auto;
}
.process-item h3 {
    font-size: 22px;
    color: #393953;
    margin: 0 0 10px 0;
}
.process-item p {
    margin: 0;
}

@media only screen and (min-width: 768px) {
.bs-none{
    display: none!important;
}

}


@media only screen and (max-width: 768px) {
   .container{
        overflow-y: hidden;
       overflow-x: hidden!important;
   }
    .main-banner-two {
        padding-top: 115px!important;
        padding-bottom: 100px!important;
    }
    .main-banner-content {
        text-align: center;
        margin-right: auto;
    }
    .main-banner-content h1 {
        font-size: 35px!important;
        margin: 0 0 12px 0!important;
    }
    .main-banner-content .banner-btn {
        margin-top: 20px!important;
    }
    .main-banner-content .banner-btn .default-btn-one, .main-banner-content .banner-btn .default-btn {
        margin-left: 5px;
        margin-right: 5px;
    }
    .default-btn-one {
        margin-right: 0;
    }
    .main-banner-content {
        text-align: center;
        margin-right: auto;
    }
    .main-banner-two .banner-image {
        margin-top: 30px!important;
    }
    .main-banner-two .banner-image img:nth-child(1) {
        display: none;
    }
    .main-banner-two .banner-image img {
        position: relative;
    }
    .main-banner-two .banner-image img:nth-child(2) {
        display: none;
    }
    .main-banner-two .banner-image img:nth-child(3) {
        display: none;
    }
    .main-banner-two .banner-image img:nth-child(4) {
        display: none;
    }
    .main-banner-two .banner-image img:nth-child(5) {
        display: none;
    }
    .main-banner-two .banner-image img:last-child {
        display: block!important;
    }
    .main-banner-two .circle-img {
        display: none;
    }
    .main-banner-two .creative-shape {
        bottom: -3px!important;
    }
    .d-table-cell {
        display: unset!important;
    }
    .section-title h2 {
        font-size: 26px!important;
        margin: 5px 0 0 0;
    }
    .section-title span {
        font-size: 15px!important;
    }
    .single-services-box {
        padding-top: 25px!important;
        padding-bottom: 25px!important;
        padding-right: 25px!important;
        padding-left: 25px!important;
    }
    .single-services-box .icon {
        width: 40px!important;
        height: 40px!important;
        line-height: 42px!important;
    }
    .single-services-box .icon i::before {
        font-size: 20px!important;
    }
    .single-services-box img {
        height: 23px;
        width: 23px;
        margin: 0 auto;
    }
    .single-services-box h3 {
        font-size: 18px!important;
        top: 35px!important;
        left: 75px!important;
    }
    .px-12 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .spb-20{
        padding-bottom: 1rem;
    }
    .spt-20 {
        padding-top: 3rem;
    }
    .spt-24{
        padding-top: 1rem;
    }
    .spt-50{
        padding-top: 50px!important;
    }
    .spb-0{
        padding-bottom: 0px!important;
    }
    .s-t-center{
        text-align: center;
    }
    .h-50 {
        height: 40px!important;
    }
    .s-mt-3{
        margin-top: 3rem;
    }
    .productive-content h3 {
        font-size: 26px!important;
        margin: 5px 0 8px 0;
    }
    .text-3xl{
        font-size: 1.4rem;
    }
    .h-300 {
        height: 180px;
    }
    .s-pt-8{
        padding-bottom: 8rem;
    }
    .page-title-area{
        height: 200px;
    }
    .page-title-content {
        text-align: center;
        margin-top: 100px;
    }
    .pt-48 {
        padding-top: 2rem;
    }
    .pt-24 {
        padding-top: 3rem;
    }
    .pb-32 {
        padding-bottom: 3rem;
    }
    .mt-24 {
        margin-top: 3rem;
    }
    .pt-100 {
        padding-top: 0px!important;
    }
    body {
        overflow-x: hidden!important;
    }
    .page-title-content h2 {
        font-size: 30px;
    }
    .pb-20 {
        padding-bottom: 1rem;
    }
   
    .relative .ml-auto{
        margin-bottom: 30px;
    }
    .py-20 {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
    .block-details .border-red{
        margin-top: 0px!important;
    }
    .pt-20 {
        padding-top: 2rem;
    }
    .contact-image img{
        text-align: center;
    height: 250px;
    padding-bottom: 30px;
    margin: 0 auto;
    }
    .ptb-100 {
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    .mt-48 {
        margin-top: 3rem;
    }
    .content{
        font-size: 24px!important;
    }
    .content__container__list__item{
        text-align: center;
    }
    .content__container:before {
        content: "[";
        margin-left: unset!important;
        left: 50px;
    }
    .content__container:after {
        right: 50px!important;
    }
    .main-banner-two {
        height: 875px!important;
    }
    .spt-10{
        padding-top: 10rem;
    }
    .-mt-64 {
        margin-top: 0rem!important;
    }
    .s-pt-2{
        padding-top: 2rem!important;
    }
    .s-pb-2 {
        padding-bottom: 2rem!important;
    }
    .m-center{
        margin: 0 auto;
    }
    .top_header_wrapper{
        display: none;
    }
    .scroll-on-mobile{
        height: 300px;
        overflow-y: auto;
    }

  }
  




  .content {
    /* position: absolute; */
    top: 0%;
    /* left: 50%;
    transform: translate(-50%, -50%); */
    height: 60px;
    overflow: hidden;
    font-family: 'Poppins', sans-serif!important;
    font-size: 35px;
    line-height: 40px;
    color: #17a5c4;
  }
  .content__container {
    font-weight: 600;
    overflow: hidden;
    height: 45px;
    padding: 0 20px;
  }
  .content__container:before {
    content: "[";
    margin-left: -30px;
    /* left: 0; */
  }
  .content__container:after {
    content: "]";
    position: absolute;
    right: 275px;
  }
  .content__container:after, .content__container:before {
    position: absolute;
    top: 0;
    color: #b5d661;
    font-size: 42px;
    line-height: 40px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .content__container__text {
    display: inline;
    float: left;
    margin: 0;
  }
  .content__container__list {
    margin-top: 0;
    padding-left: 0px;
    text-align: left;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
  .content__container__list__item {
    line-height: 45px;
    margin: 0;
    font-size: 24px;
  }
  
  @-webkit-keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @-webkit-keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }
  @keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }
  .main-banner-image {
    margin-right: auto;
    max-width: 550px;
}
.h-210 {
    height: 190px;
    padding-top: 50px;
}
  .main-banner-content h1{
    font-family: "Roboto Condensed", sans-serif;
  }
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  
  canvas {
    cursor: none;
    position: absolute;
    top: 0;
    left: 0;
    
  }
  .solutions a:hover{
    color:#14a6c4!important;
  }
  .p-tb-10{
      padding: 10px 0px;
  }
  .banner_img{
      width: 500px;
      max-width: 100%;
  }
  .top-navbar li a{
    font-size: 14px;
    padding: 10px 15px;
  }
  .text-xl {
    font-size: 1.1rem;
    line-height: 1.75rem;
}


.swal2-styled.swal2-confirm {
    background-color: #25a5c4!important;
    color: #fff;
}  
.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(38 166 195)!important;
}

/* top nav style */
.top_header_wrapper {
    float: left;
    width: 100%;
    /* background: linear-gradient(to right, rgb(57, 187, 228) 0%, rgb(25, 178, 162) 100%); */
    background: linear-gradient(to right, #169799 0%, #20c3c5 100%);
    color: #ffffff;
    position: relative;
    z-index: 6;

}
.top_header_add ul {
    margin: 0px;
    padding: 0px;
    width: 100%;
}
.top_header_add li {
    float: left;
    width: auto;
    list-style: none;
    margin-right: 30px;
}
.top_header_add ul li i {
    margin-right: 10px;
    line-height: 16px;
    width: 25px;
    height: 20px;
    text-align: center;
}
.top_header_add {
    float: left;
    width: auto;
    margin-top: 5px;
}
.top_login {
    float: right;
    width: auto;
    padding-right: 15px;
    text-align: center;

}
.p-r-0{
    padding-right: 0;
}
.certification{
    margin-top: 3rem;
    border-radius: 20px;
    padding: 22px;
    box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
    background-color: #fff;
}

.loader-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.834)
        url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
        no-repeat;
    z-index: 1;
}


.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  .icon-style{
    background: linear-gradient(to right, #25a5c4 0%, #b5d560 100%);
    /* background-color: #25a5c4; */
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #25a5c4;
    border: 2px solid #25a5c4;
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }